<template>
    <div class="transactions">
        <b-row>
            <b-sidebar id="sidebar" ref="mySidebar" title=""  shadow
                       aria-labelledby="sidebar-no-header-title"
                       no-header
                       backdrop-variant="dark"
                       backdrop>

                <template #footer="{ }">

                    <div class="bg-dark text-light align-items-center px-3 py-2 ft">
                        <b-row>
                            <div class="d-flex">
                                <div class="logo">
                                    <img src="../../../assets/logo.png" alt="logo" class="dp">
                                </div>
                                <div class="shop-details">
                                    <span class="font-weight-bold">{{name}}</span><br/>
                                    <span class="small">{{location}}</span>
                                </div>
                            </div>
                        </b-row>
                        <b-row>
                            <div class="logout">
                                <button class="logout-btn" @click="logout()">Logout</button>
                            </div>
                        </b-row>
                    </div>

                </template>

                <div class="px-3 py-2 side-menu bg-dark">
                    <div class="y-menu-list-group">
                        <a @click="loadPage(checkoutPath)" :class="[currentPage == '/home/checkout' ? 'active':'y-menu-list-group-item']">
                            Checkout
                        </a>

                        <a @click="loadPage('/home/transactions')" :class="[currentPage == '/home/transactions' ? 'active':'y-menu-list-group-item']">
                            Transactions
                        </a>

                        <a v-if="loyalty" @click="loadPage('/home/redeem')" :class="[currentPage == '/home/redeem' ? 'active':'y-menu-list-group-item']">
                            Redeems
                        </a>

                        <a @click="loadPage('/home/expenses')" :class="[currentPage == '/home/expenses' ? 'active':'y-menu-list-group-item']">
                            Expenses
                        </a>

                        <a @click="loadPage('/home/apps')"  :class="[currentPage == '/home/apps' ? 'active':'y-menu-list-group-item']">
                            Apps
                        </a>
                    </div>
                </div>
            </b-sidebar>
        </b-row>

        <b-row class="header">
            <b-col cols="5" sm="5" md="5" lg="5">
                <b-row>
                    <img v-b-toggle.sidebar src="../../../assets/ic_grid.png" class="y-menu-icon"/>
                    <div style="margin-top: auto; margin-bottom: auto;">
                        <span class="section-title">Transactions</span>
                    </div>
                </b-row>
            </b-col>
            <div class="header-separator"></div>
            <b-col cols="6">
                <b-row >
                    <b-col cols="12">
                        <p class="header-total text-center">UGX {{Number(total).toLocaleString()}} Sale</p>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
<!--        content-->
        <b-row class="content">
            <!--library left-->
            <b-col cols="5" md="5" lg="5" ref="list" class="left-content scroll_hide">
                <b-row>
                    <b-col cols="12">
                        <div class="search-input">
                            <input type="text" class="input"
                                   placeholder="Search All Items"
                                   aria-label="Recipient's username" v-model="search_text" aria-describedby="basic-addon2">
                        </div>
                    </b-col>
                </b-row>
                <b-overlay :show="loading" rounded="sm" >
                    <b-row v-if="getTransactionsMeta.current_page > 1">
                    <b-col cols="4"></b-col>
                    <b-col cols="4" class="align-content-center text-center" style="margin: 10px;">
                      <button
                          class="button"
                          @click="lazyLoadUp">
                           Load  Previous ({{getTransactionsMeta.current_page}}/{{getTransactionsMeta.last_page}})
                      </button>
                    </b-col>
                    <b-col cols="4"></b-col>
                  </b-row>
                    <b-row>
                        <b-col cols="12" style="padding: 0;">
                            <div  class="flex-grow-1">
                                <div class="list-group" v-for="(transaction, idx) in getTransactions" :key="idx">
                                    <b-row class="list-group-header separator">
                                        <b-col cols="6">
                                            <span class="date">
                                                {{getDateString(transaction.date)}}
                                            </span>
                                        </b-col>

                                        <b-col cols="6">
                                            <div style="float: right;">
                                                <span class="date-amount">
                                                    UGX {{Number(transaction.total).toLocaleString()}}
                                                </span>
                                            </div>
                                        </b-col>
                                    </b-row>

                                    <b-row :class="[txn.id == selected_id ? 'y-list-group-item-active less-item' : 'y-list-group-item less-item']"
                                           @click="show(txn)"
                                           v-for="(txn, idx) in transaction.transactions"
                                           :key="idx">
                                        <b-col cols="9">
                                            <div class="d-flex align-items-center">
                                                <div class="anchor">
                                                    <img v-if="txn.refund_status" class="refund-icon">
                                                    <img v-else-if="txn.payment_type == 'check-in'" class="checkin-icon">
                                                    <img v-else-if="txn.payment_type == 'cash'" class="cash-icon">
                                                    <img v-else-if="txn.payment_type == 'mobile_money'" class="momo-icon">
                                                    <img v-else-if="txn.payment_type == 'card'" class="card-icon">
                                                    <img v-else-if="txn.payment_type == 'redeem'" class="redeem-icon">
                                                </div>
                                                <div>
                                                    <p class="mb-0">UGX {{Number(txn.total_cash).toLocaleString()}}</p>
                                                    <small>{{txn.details[0].item_name}}</small>
                                                </div>
                                            </div>
                                        </b-col>
                                        <b-col cols="3">
                                            <p class="mb-0 timestamp left-figures">
                                                {{getTime(txn.created_at)}}
                                            </p>
                                        </b-col>
                                    </b-row>
                                </div>
                            </div>
                        </b-col>
                    </b-row>
                    <b-row v-if="getTransactionsMeta.current_page < getTransactionsMeta.last_page">
                      <b-col cols="4"></b-col>
                      <b-col cols="4" class="align-content-center text-center" style="margin: 10px;">
                        <button
                            class="button"
                            @click="lazyLoadDown">
                              Load More  ({{getTransactionsMeta.current_page}}/{{getTransactionsMeta.last_page}})
                        </button>
                      </b-col>
                      <b-col cols="4"></b-col>
                    </b-row>
                </b-overlay>
            </b-col>
            <!--Library right -->
            <b-col cols="7" md="7" lg="7" class="right-content scroll_hide">
                <div v-if="visible" class="body flex-grow-1 p-5">

                    <b-row>
                        <button @click="refundAction" class="btn y-btn-outline-dark text-danger py-3 px-5 mr-3 font-weight-bold"> Issue Refund</button>
                        <a @click="print" class="btn y-btn-outline-dark text-danger py-3 px-5 font-weight-bold"> Print Receipt</a>
                    </b-row>

                    <b-row class="list-group list-group-flush mt-5">

                        <div class="mb-2 d-flex justify-content-between align-items-center">
                            <span class="section-header">CASH PAYMENT</span>
                        </div>
                        <div class="bar"></div>
                        <a class="y-list-item list-group-item-action theme view ">
                            <div class="d-flex align-items-center">
                                <div class="anchor">
                                    <img v-if="transaction.refund_status" src="../../../assets/ic_refund.png" class="y-icon">
                                    <img v-else-if="transaction.payment_type == 'check-in'" src="../../../assets/ic_checkin_member.png" class="y-icon">
                                    <img v-else-if="transaction.payment_type == 'cash'" src="../../../assets/ic_cash.png" class="y-icon">
                                    <img v-else-if="transaction.payment_type == 'mobile_money'" src="../../../assets/ic_mobile_money.png" class="y-icon">
                                    <img v-else-if="transaction.payment_type == 'card'" src="../../../assets/ic_card.png" class="y-icon">
                                    <img v-else-if="transaction.payment_type == 'redeem'" src="../../../assets/ic_redeem.svg" class="y-icon">
                                </div>
                                <div>
                                    <p class="mb-0 text-capitalize" v-if="transaction.payment_type != null">{{cleanText(transaction.payment_type)}}</p>
                                </div>
                            </div>
                        </a>

                        <a class="y-list-item list-group-item-action theme view ">
                            <div class="d-flex align-items-center">
                                <div class="anchor">
                                    <img src="../../../assets/ic_receipt.png" class="y-icon">
                                </div>
                                <div>
                                    <p class="mb-0 font-weight-bold">#{{receipt}}</p>
                                </div>
                            </div>
                        </a>
                      <a class="y-list-item list-group-item-action theme view ">
                        <div class="d-flex align-items-center">
                          <div class="anchor">
                            <img src="../../../assets/ic_user.png" class="y-icon">
                          </div>
                          <div>
                            <p class="mb-0 mt-2 font-weight-bold">{{transaction.customer.name}}</p>
                            <p class="mb-0">{{transaction.customer.phone_number}}</p>
                          </div>
                        </div>
                      </a>
                    </b-row>

                    <b-row class="list-group list-group-flush mt-5">
                        <div class="mb-2 d-flex justify-content-between align-items-center">
                            <span class="section-header">ITEMS</span>
                        </div>
                        <div class="bar"></div>
                        <b-row class="y-list-item less" v-for="(product, idx) in products" :key="idx">
                            <b-col cols="12">
                                <b-row class="align-items-center item-line">
                                    <b-col cols="1" class="item-initials">
                                        <div class="initials">
                                            {{getInitial(product.item_name)}}
                                        </div>
                                    </b-col>
                                    <b-col cols="11" class="item-details">
                                        <b-row>
                                            <b-col cols="8" style="margin: auto;">
                                                <b-row>
                                                    <span>{{product.item_name}}</span>
                                                    <span class="qty">x{{product.item_quantity}}</span>
                                                </b-row>
                                            </b-col>

                                            <b-col cols="4">
                                                <span style="float: right;">
                                                    UGX {{Number(product.item_subtotal).toLocaleString()}}
                                                </span>
                                            </b-col>
                                        </b-row>
                                        <b-row  v-if="product.discount">
                                            <b-col cols="10">
                                                <b-row>
                                                    <small class="mb-0" >{{product.discount.discount_name}}</small>
                                                </b-row>
                                            </b-col>
                                            <b-col cols="2">
                                                <small v-if="product.discount" style="float: right;">
                                                    - UGX. {{calculateDiscount(product)}}
                                                </small>
                                            </b-col>
                                        </b-row>
                                        <b-row v-for="(member,idx) in product.team" :key="idx">
                                            <small class="mb-0" >{{member.name}}</small>
                                        </b-row>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>
                    </b-row>

                    <b-row class="list-group list-group-flush mt-5">

                        <div class="mb-2 d-flex justify-content-between align-items-center">
                            <span class="section-header">TOTAL</span>
                        </div>
                        <div class="bar"></div>
                        <b-row class="y-list-item less">
                            <b-col cols="9">
                                <div class="d-flex align-items-center">
                                    <div class="anchor">
                                        <img src="../../../assets/ic_cash.png" class="y-icon">
                                    </div>
                                    <div>
                                        <p class="mb-0">Total</p>

                                    </div>
                                </div>
                            </b-col>
                            <b-col cols="3">
                                <div class="right-figures-mini">
                                        <span>
                                            <p class="font-weight-bold">UGX {{Number(total).toLocaleString()}}</p>
                                        </span>
                                </div>
                            </b-col>
                        </b-row>
                    </b-row>
                </div>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";
    import router from "../../../app/router/router";

    export default {
        name: "Transactions",
        data(){
            return{
                search_text:null,
                months:["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
                receipt: null,
                total: null,
                products:[],
                visible:false,
                transaction:null,
                selected_id:null,
                activeClass:'active',
                currentPage: this.$route.fullPath,
                name: JSON.parse(localStorage.getItem("business")).name,
                location: localStorage.getItem("location_name"),
                loyalty:JSON.parse(localStorage.getItem('business')).active_products[1].Loyalty,
                subscriptions:JSON.parse(localStorage.getItem('business')).active_products[2].Subscriptions,
                checkoutPath:"/home/checkout",
                loading: true,
            }
        },
        created() {
            if(localStorage.getItem("token") == null){
                router.push("/")
            }else{
                this.fetchTransactions()
            }
        },
        mounted() {
            if(!this.loyalty){
                this.checkoutPath = "/library"
            }

            if(!this.subscriptions){
                this.checkoutPath = "/library"
            }

        },
        methods:{
            ...mapActions(["fetchTransactions", "fetchTransactionsPaged"]),
            onSearch(){

            },
            getDateString(date){
                var sections = date.split("-")
                var month_idx = parseInt(sections[1]) - 1
                return sections[2]+" "+this.months[month_idx]+", "+sections[0];
            },
            getTime(date){
                var sections = date.split("T")
                var sections2 = sections[1].split(".")
                var sections3 = sections[1].split(":")
                var total = parseInt(sections2[0].replaceAll(":", ""))
                if(total > 120000){
                    return sections3[0]+":"+sections3[1] + " pm"
                }else{
                    return sections3[0]+":"+sections3[1] + " am"
                }
            },
            lazyLoadUp(){
              let data = {
                page:parseInt(this.getTransactionsMeta.current_page) - 1
              }
              this.loading = true
              this.fetchTransactionsPaged(data)
            },
            lazyLoadDown(){
              let data = {
                page:parseInt(this.getTransactionsMeta.current_page) + 1
              }
              this.loading = true
              this.fetchTransactionsPaged(data)
            },
            show(data){
                this.products = data.details
                this.total = data.total_cash
                this.receipt = data.receipt_no
                this.transaction = data
                this.selected_id = data.id
            },
            calculateDiscount(product){
                var amount = product.discount.amount
                var type = product.discount.discount_type
                var price = parseInt(product.item_subtotal)/parseInt(product.item_quantity)

                if(type == "Amount"){
                    return amount;
                }

                if(type == "Percentage"){
                    return  parseInt(price) * amount/100
                }
            },
            refundAction(){
                let data = {
                    id:this.selected_id
                }
                localStorage.setItem("refund", JSON.stringify(data))
                router.push("/refund-reason")
            },
            getInitial(name){
                try{
                  return name.charAt(0).toUpperCase()+name.charAt(1)
                }catch (e){
                  return "Na"
              }
            },
            closeSideMenu(){
                this.$refs.mySidebar.hide();
            },
            loadPage(route){
                router.push(route)
            },
            capitalizeFirstLetter(string) {
                return string.charAt(0).toUpperCase() + string.slice(1);
            },
            logout(){
                localStorage.clear()
                router.push("/")
            },
            cleanText(text){
                if(text.includes("_")){
                    return this.capitalizeFirstLetter(text.replaceAll("_", " "))
                }
                return text;
            },
            print(){
                localStorage.setItem("transaction", JSON.stringify(this.transaction))
                router.push("/print-receipt")
            }
        },
        computed:{
            ...mapGetters([
                "getTransactions",
                "getTransactionsMeta",
                "getTransactionErrorResponse",
            ]),
            searchText(){
                return this.search_text
            },
        },
        watch:{
            stretchText(){
                this.onSearch()
            },
            getTransactions(data){
                this.visible = true
                this.loading = false
                this.show(data[0].transactions[0])
            }
        },
        beforeDestroy() {
          window.removeEventListener('scroll', this.onScroll);
        }

    }
</script>

<style scoped>
    @import "../../../commons/css/main.css";
    @import "../../../commons/css/menu.css";
    @import "../../../commons/css/utils.css";

    .transactions{
        overflow: hidden;
    }

    .header{
        margin: 0; padding: 0;
        width: 100%;
        z-index: 20;
        position: fixed;
        border-bottom: solid 2px #c4c4c4;
        background: white;
    }

    .header-total{
        font-size: 22px;
        font-weight: bold;
        margin-top: 25px;
    }

    .header-separator{
        background: #c4c4c4;
        width: 2px;
    }

    .content{
        margin: 85px 0px 0px 0px;
    }

    .left-content{
        border-right: solid 2px #c4c4c4;
        position: fixed;
        width: 100%;
        height:calc(100% - 85px);
        left: 0;
        right: 0;
        overflow-y: scroll;
        top: 85px;
    }

    .right-content{
        margin: 60px 0 0 250px;
        padding: 0 30px;
        overflow-y: scroll;
        position: fixed;
        right: 0;
        top: 0;
        height: calc(100% - 60px);
    }

    .y-icon{
        width: 32px;
        height: 32px;
    }

    .anchor{
        padding: 10px;
        margin-right: 15px;
    }

    .y-list-group-item{
        padding: 10px 10px;
        font-weight: bold;
        border-bottom: solid 1px #bfbfbf;
    }

    .y-list-group-item-active{
        padding: 10px 10px;
        font-weight: bold;
        background: #b42c38;
        color: white;
    }

    .item-initials{
        display: flex;
        background: #a0a0a0;
        width: 75px;
        height: 80px;
        color: white;
        align-items: center;
        justify-content: center;
        font-weight: bold;
    }

    .item-details{
        height: 100%;
        padding-left: 30px;
        padding-top: 5px;
        padding-bottom: 5px;
        float: right;
    }

    .timestamp{
        font-size: 16px;
        font-weight: normal;
    }

    .y-list-group-item:hover{
        background: #db3644;
        color: white;
    }

    .bar{
        width: 100%;
        background: #a0a0a0;
        height: 1px;
    }

    .y-list-item{
        width: 100%;
        border-bottom: solid 1px #a0a0a0;
    }

    .y-list-item:hover{
        background: #eaeaea;
    }

    .text-details{
        color: #a0a0a0;
    }

    .qty{
        margin-left: 10px;
        color: #a0a0a0;
    }

    .search-input{
        padding: 20px;
    }

    .input{
        width: 100%;
        border: 0 none;
    }

    .input:focus{
        border: 0 none;
    }

    .input:active{
        border: 0 none;
    }

    input:focus, textarea:focus, select:focus{
        outline: none;
    }

    .button{
      padding: 10px 10px;
      background: #b42c38;
      color: white;
      border: 0;
      border-radius: 3px;
    }

    .left-figures{
        justify-items: right;
        text-align: right;
        margin-top: 14px;
    }

    .right-figures{
        margin-top: 25px;
    }

    .right-figures-mini{
        margin-top: 12px;
    }

    .separator{
        background: #ededed;
        padding: 15px 10px;
        margin: 0;
        width: 100%;
    }

    .y-btn-outline-dark {
        color: #343a40;
        background-color: transparent;
        background-image: none;
        border-color: #343a40;
    }
    .y-btn-outline-dark:hover {
        color: #fff;
        background-color: #b0b0b0;
        border-color: #b0b0b0;
    }

    .right-figures span{
        text-align: right;
        justify-items: right;
    }

    .right-figures-mini span{
        text-align: right;
        justify-items: right;
    }

    .section-header{
        font-weight: bold;
    }

    .card-icon{
        width: 32px;
        height: 32px;
        content: url("../../../assets/ic_card.png");
    }

    .momo-icon{
        width: 32px;
        height: 32px;
        content: url("../../../assets/ic_mobile_money.png");
    }

    .cash-icon{
        width: 32px;
        height: 32px;
       content: url("../../../assets/ic_cash.png");
    }

    .refund-icon{
        width: 32px;
        height: 32px;
        content: url("../../../assets/ic_refund.png");
    }

    .checkin-icon{
        width: 32px;
        height: 32px;
        content: url("../../../assets/ic_checkin_member.png");
    }

    .redeem-icon{
        width: 32px;
        height: 32px;
        content: url("../../../assets/ic_redeem.svg");
    }

    .y-list-group-item:hover .refund-icon{
        content: url("../../../assets/ic_refund_white.png");
    }

    .y-list-group-item:hover .cash-icon{
        content: url("../../../assets/ic_cash_active.png");
    }

    .y-list-group-item:hover .momo-icon{
        content: url("../../../assets/ic_mobile_money_white.png");
    }

    .y-list-group-item:hover .card-icon{
        content: url("../../../assets/ic_card_white.png");
    }

    .y-list-group-item:hover .checkin-icon{
        content: url("../../../assets/ic_checkin_member_white.png");
    }

    .y-list-group-item-active .cash-icon{
        content: url("../../../assets/ic_cash_active.png");
    }

    .y-list-group-item-active .card-icon{
        content: url("../../../assets/ic_card_white.png");
    }

    .y-list-group-item-active .momo-icon{
        content: url("../../../assets/ic_mobile_money_white.png");
    }

    .y-list-group-item-active .refund-icon{
        content: url("../../../assets/ic_refund_white.png");
    }

    .y-list-group-item-active .checkin-icon{
        content: url("../../../assets/ic_checkin_member_white.png");
    }

    .y-list-group-item-active .redeem-icon{
        content: url("../../../assets/ic_redeem_white.svg");
    }

    @media only screen and (max-width: 1119px){

        .date{
            font-size: 11px;
        }

        .date-amount{
            font-size: 11px;
        }

        .separator{
            padding: 5px;
        }

        .header-separator{
            margin-left: 10px;
        }
    }

    @media only screen and (max-width: 380px) {

    }

    @media only screen and (max-width: 320px) {

    }

</style>